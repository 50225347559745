// Pagination
//
// <h5>Description</h5>
// <p>A method for allowing a user to view a subset of sorted data into a more comprehensible format. A long list can be divided into several pages by Pagination, and only one page will be loaded at a time.</p>
//
// <h5>Context of use</h5>
// <p>Use when it is unsuitable to display all the data on a single page/screen. Use when the dataset is in some way ordered.</p>
//
// <h5>Where it is used</h5>
// <p>In the Page Action Bar</p>
//
// <h5>How it works</h5>
// <ul>
// <li>Clicking on a number will load information for that page of the list</li>
// <li>User can navigate between pages using the previous and next arrows</li>
// <li>The previous arrow will be disabled when we are on the first page of the list</li>
// <li>The next arrow will be disabled when we are on the last page of the list</li>
// <li>The first and last page number will always be visible</li>
// </ul>
//
// Markup: ../../../html/pagination.html
//
// Styleguide Components.Pagination


//
// Base styles
//


@use "sass:math";

.pagination {
  display: flex;
  @include list-unstyled();
  @include border-radius();
}

.page-link {
  position: relative;
  display: inline-block;
  padding: $pagination-padding-y $pagination-padding-x;
  margin-left: -$pagination-border-width;
  line-height: $pagination-line-height;
  color: $pagination-color;
  background-color: $pagination-bg;
  border-radius: $border-radius;
  transition: $transition-base;
  border: unset;
  &:hover {
    color: $pagination-hover-color;
    background-color: $pagination-hover-bg;
    border-color: $pagination-hover-border-color;
  }

  &:focus {
    z-index: 2;
    outline: 0;
    box-shadow: $pagination-focus-box-shadow;
  }

  // Opinionated: add "hand" cursor to non-disabled .page-link elements
  &:not(:disabled):not(.disabled) {
    cursor: pointer;
  }
}

.page-item {
  white-space: nowrap;
  &:first-child {
    .page-link {
      margin-left: 0;
      border: $pagination-border-width solid $pagination-border-color;
    }
  }
  &:last-child {
    .page-link {
      border: $pagination-border-width solid $pagination-border-color;
    }
  }
  &--start .page-link {
    padding-right: math.div($pagination-padding-x, 4);
  }
  &--end .page-link {
    padding-left: math.div($pagination-padding-x, 4);
  }
  &--start:after, &--end:before {
    display: inline-block;
    vertical-align: baseline;
    content: '...';
  }
  &.active .page-link {
    z-index: 1;
    color: $pagination-active-color;
    background-color: $pagination-active-bg;
    border-color: $pagination-active-border-color;
    text-decoration: underline;
  }

  &.disabled .page-link {
    color: $pagination-disabled-color;
    pointer-events: none;
    // Opinionated: remove the "hand" cursor set previously for .page-link
    cursor: auto;
    background-color: $pagination-disabled-bg;
    border-color: $pagination-disabled-border-color;
  }
}


//
// Sizing
//

.pagination-lg {
  @include pagination-size($pagination-padding-y-lg, $pagination-padding-x-lg, $font-size-lg, $line-height-lg, $border-radius-lg);
}

.pagination-sm {
  @include pagination-size($pagination-padding-y-sm, $pagination-padding-x-sm, $font-size-sm, $line-height-sm, $border-radius-sm);
}

/* source-sans-pro-300 - latin */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 300;
  src: local('Source Sans Pro Light'), local('SourceSansPro-Light'),
       url('../fonts/source-sans-pro-v13-latin-300.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('../fonts/source-sans-pro-v13-latin-300.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* source-sans-pro-600 - latin */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 600;
  src: local('Source Sans Pro SemiBold'), local('SourceSansPro-SemiBold'),
       url('../fonts/source-sans-pro-v13-latin-600.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('../fonts/source-sans-pro-v13-latin-600.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* source-sans-pro-regular - latin */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  src: local('Source Sans Pro Regular'), local('SourceSansPro-Regular'),
       url('../fonts/source-sans-pro-v13-latin-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('../fonts/source-sans-pro-v13-latin-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}



@import "custom/global/grid";
@import "custom/global/variables";

@import "~bootstrap/scss/bootstrap";
@import "custom/global/custom-mixins";
@import "~dropkiq-ui/dist/dropkiq";
@import 'react-datepicker/dist/react-datepicker.css';

// // Mixin Components
@import "custom/mixins/buttons";

@import "custom/global/type";
@import "custom/global/tables";
@import "custom/global/utilities";
@import "custom/components/forms";
@import "custom/components/dropdown";
@import "custom/components/breadcrumb";
@import "custom/components/pagination";
@import "custom/components/badges";
@import "custom/components/modal";
@import "custom/components/tooltip";
@import "custom/global/scaffolding";
@import "custom/components/alert";
@import "custom/components/avatar";
@import "custom/components/buttons";
@import "custom/components/cards";
@import "custom/components/datepicker";
@import "custom/components/checkbox";
@import "custom/components/header";
@import "custom/components/toggle";
@import "custom/components/tri-toggle";
@import "custom/components/sidebar";
@import "custom/components/project";
@import "custom/components/search";
@import "custom/components/file-select";
@import "custom/components/banner";
@import "custom/components/action-bar";
@import "custom/components/data-desc";
@import "custom/components/toolbar";
@import "custom/components/selection-list";
@import "custom/components/progress-track";
@import "custom/components/permission-table";
@import "custom/components/progress";

@import "custom/components/react-select";
@import "custom/components/react-datepicker";
@import "custom/components/react-toastify";

@import "custom/components/typeahead";
@import "custom/components/keyboard";
@import "custom/components/spreadsheet";
@import "custom/components/register";

@import "custom/overrides";

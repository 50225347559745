// Cards
//
// <h5>Description</h5>
// <p>Cards are used to group information and sections of related information. Cards come with their own headers to denote the content and can feature individual buttons in the header that relates to that content specifically. Some card types can also feature expandable panels depending on the content type.</p>
//
// <h5>Where it is used</h5>
// <p>Dashboards, group and list tabs on project over view pages, new comms page.</p>
//
// <h5>How it works</h5>
// <ul>
// <li>Cards can have a number of different types of content inside them.</li>
// <li>Cards live independently and should not be stacked inside one another.</li>
// <li>In scenarios where the cards are in two columns, the pairs should always match the same height for visual consistency.</li>
// <li>When there is blank content or space due to the match height or an empty state, then the card should have a darker background. See the contact list and action needed panel as examples.</li>
// </ul>
//
// Markup: ../../../html/cards.html
//
// Styleguide Components.cards


//
// Base styles
//

@use "sass:math";

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: $card-bg;
  background-clip: border-box;
  color: $gray-800;
  border: $card-border-width solid $card-border-color;
  @include border-radius($card-border-radius);

  > hr {
    margin-right: 0;
    margin-left: 0;
  }

  > .list-group:first-child {
    .list-group-item:first-child {
      @include border-top-radius($card-border-radius);
    }
  }

  > .list-group:last-child {
    .list-group-item:last-child {
      @include border-bottom-radius($card-border-radius);
    }
  }
}

.card-list__link,
.card-list__badge,
.card-list__button,
.card-list__text {
  font-size: 0.8125rem;
  white-space: nowrap;
  margin-left: 0.75rem;
  &:first-child {
    margin-left: 0;
  }
}

.card-list__button {
  &--default {
    @include button-variant($teal, $teal);
    color: #fff;
  }
  .fal {
    margin-right: 0.5rem;
  }
}

.card-list__badge--default {
  background-color: $boston;
  color: #fff;
}
.card-list__avatars {
  float: left;
  white-space: nowrap;
  margin-right: 0.8125rem;
  + a {
    line-height: 36px;
  }
}
.card-list__avatar {
  width: 36px;
  margin-left: -0.8125rem;
  &:first-child {
    margin-left: 0;
  }
}

.card-list-collapse__toggle {
  .card-list__link {
    line-height: 1rem;
  }
  .fal {
    margin-left: 2rem;
    font-size: 1.375rem;
    color: $blue-light;
  }
  &.collapsed {
    .fal {
      // .fa-plus-square; TODO (tgraham): could this be done in code?
      content: "\f0fe";
    }
  }
}
.card-list-collapse__content {
  color: $east-bay;
  margin-bottom: -0.75rem;
  margin-top: 0.75rem;
  margin-left: -1.25rem;
  margin-right: -1.25rem;
  .list-group {
    border-top: $card-border-width solid $card-border-color;
  }
  .list-group-item {
    background-color: $gray-100;
  }
  .list-group .list-group-item a {
    color: $east-bay;
  }
  .fal {
    font-size: 1.375rem;
    color: $blue-light;
  }
}

.card-body {
  // Enable `flex-grow: 1` for decks and groups so that card blocks take up
  // as much space as possible, ensuring footers are aligned to the bottom.
  flex: 1 1 auto;
  padding: $card-spacer-x;
  > ol {
    padding-left: 1.5rem;
  }
}

.card-title {
  margin-bottom: $card-spacer-y;
}

.card-subtitle {
  margin-top: -(math.div($card-spacer-y, 2));
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link {

  + .card-link {
    margin-left: $card-spacer-x;
  }
}

//
// Optional textual caps
//

.card-header {
  padding: $card-spacer-y $card-spacer-x;
  margin-bottom: 0; // Removes the default margin-bottom of <hN>
  color: $blue;
  font-size: $h3-font-size;
  font-weight: normal;
  background-color: $card-cap-bg;
  border-bottom: $card-border-width solid $card-border-color;

  .fal {
    margin-left: -0.375rem;
    margin-right: 0.375rem;
  }

  .text-muted {
    margin-left: 0.25rem;
  }

  &:first-child {
    @include border-radius($card-inner-border-radius $card-inner-border-radius 0 0);
  }

  .list-group {
    a {
      color: $gray-800;
    }
    .list-group-item:first-child {
      border-top: 0;
    }
  }
}

// Custom

.card-header__links {
  .fal {
    margin-left: 0;
    margin-right: 0.25rem;
  }
}

.card-header__link {
  white-space: nowrap;
  font-size: 1rem;
  margin-left: 1em;
  &:first-child {
    margin-left: 0rem;
  }
  &--disabled {
    color: $gray-600;
  }
  &--small {
    color: $cornflower;
    font-size: 0.8125rem;
  }
}

.card-footer {
  padding: $card-spacer-y $card-spacer-x;
  background-color: $card-cap-bg;
  border-top: $card-border-width solid $card-border-color;

  &:last-child {
    @include border-radius(0 0 $card-inner-border-radius $card-inner-border-radius);
  }
}


//
// Header navs
//

.card-header-tabs {
  margin-right: -(math.div($card-spacer-x, 2));
  margin-bottom: -$card-spacer-y;
  margin-left: -(math.div($card-spacer-x, 2));
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -(math.div($card-spacer-x, 2));
  margin-left: -(math.div($card-spacer-x, 2));
}

// Card image
.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: $card-img-overlay-padding;
}

.card-img {
  width: 100%; // Required because we use flexbox and this inherently applies align-self: stretch
  @include border-radius($card-inner-border-radius);
}

// Card image caps
.card-img-top {
  width: 100%; // Required because we use flexbox and this inherently applies align-self: stretch
  @include border-top-radius($card-inner-border-radius);
}

.card-img-bottom {
  width: 100%; // Required because we use flexbox and this inherently applies align-self: stretch
  @include border-bottom-radius($card-inner-border-radius);
}


// Card deck

.card-deck {
  display: flex;
  flex-direction: column;

  .card {
    margin-bottom: $card-deck-margin;
  }

  @include media-breakpoint-up(sm) {
    flex-flow: row wrap;
    margin-right: -$card-deck-margin;
    margin-left: -$card-deck-margin;

    .card {
      display: flex;
      // Flexbugs #4: https://github.com/philipwalton/flexbugs#4-flex-shorthand-declarations-with-unitless-flex-basis-values-are-ignored
      flex: 1 0 0%;
      flex-direction: column;
      margin-right: $card-deck-margin;
      margin-bottom: 0; // Override the default
      margin-left: $card-deck-margin;
    }
  }
}


//
// Card groups
//

.card-group {
  display: flex;
  flex-direction: column;

  // The child selector allows nested `.card` within `.card-group`
  // to display properly.
  > .card {
    margin-bottom: $card-group-margin;
  }

  @include media-breakpoint-up(sm) {
    flex-flow: row wrap;
    // The child selector allows nested `.card` within `.card-group`
    // to display properly.
    > .card {
      // Flexbugs #4: https://github.com/philipwalton/flexbugs#4-flex-shorthand-declarations-with-unitless-flex-basis-values-are-ignored
      flex: 1 0 0%;
      margin-bottom: 0;

      + .card {
        margin-left: 0;
        border-left: 0;
      }

      // Handle rounded corners
      @if $enable-rounded {
        &:first-child {
          @include border-right-radius(0);

          .card-img-top,
          .card-header {
            border-top-right-radius: 0;
          }
          .card-img-bottom,
          .card-footer {
            border-bottom-right-radius: 0;
          }
        }

        &:last-child {
          @include border-left-radius(0);

          .card-img-top,
          .card-header {
            border-top-left-radius: 0;
          }
          .card-img-bottom,
          .card-footer {
            border-bottom-left-radius: 0;
          }
        }

        &:only-child {
          @include border-radius($card-border-radius);

          .card-img-top,
          .card-header {
            @include border-top-radius($card-border-radius);
          }
          .card-img-bottom,
          .card-footer {
            @include border-bottom-radius($card-border-radius);
          }
        }

        &:not(:first-child):not(:last-child):not(:only-child) {
          @include border-radius(0);

          .card-img-top,
          .card-img-bottom,
          .card-header,
          .card-footer {
            @include border-radius(0);
          }
        }
      }
    }
  }
}


//
// Columns
//

.card-columns {
  .card {
    margin-bottom: $card-columns-margin;
  }

  @include media-breakpoint-up(sm) {
    column-count: $card-columns-count;
    column-gap: $card-columns-gap;

    .card {
      display: inline-block; // Don't let them vertically span multiple columns
      width: 100%; // Don't let their width change
    }
  }
}

// Tool tips
//
// <h5>Description</h5>
// <p>Tooltips are a hint or tip on what a tool or other interaction does. They can also be beneficial when explaining icon meanings on smaller interfaces.</p>
//
// <h5>Context of use</h5>
// <p>Used for information that is useful but not necessarily required on the UI so can be hidden. It should be used where users will need eduction on an ambigious action or additional meaning needed. The length of tool tips should be kept fairly short - if more then a couple of sentances are needed, consider implimenting a different pattern such as a well or FAQ page.</p>
//
// <h5>Where it is used</h5>
// <p>Sidebar menu, across the site on helper and information icons</p>
//
// <h5>How it works</h5>
// <ul>
// <li>Tooltips should automatically align towards the area of the screen that has the most free space</li>
// <li>Mobile users can activate a tooltip by clicking on the appropriate icon</li>
// <li>The ‘information’ icon should be used when the content is additional information or expanding on the related copy</li>
// <li>The ‘question’ icon should be used when the content is a hinting on how a user should proceed or giving direction</li>
// </ul>
//
// Markup: ../../../html/tooltip.html
//
// left - Tooltip on the left.
// top - Tooltip on the top.
// bottom - Tooltip on the bottom.
// right - Tooltip on the right.
//
// Styleguide Components.tooltip
// Base class
@use "sass:math";

.tooltip {
  position: absolute;
  z-index: $zindex-tooltip;
  display: block;
  margin: $tooltip-margin;
  // Our parent element can be arbitrary since tooltips are by default inserted as a sibling of their target element.
  // So reset our font and text properties to avoid inheriting weird values.
  @include reset-text();
  font-size: $tooltip-font-size;
  // Allow breaking very long words so they don't overflow the tooltip's bounds
  word-wrap: break-word;
  opacity: 0;

  &.show { opacity: $tooltip-opacity; }

  .arrow {
    position: absolute;
    display: block;
    width: $tooltip-arrow-width;
    height: $tooltip-arrow-height;

    &::before {
      position: absolute;
      content: "";
      border-color: transparent;
      border-style: solid;
    }
  }
}

.bs-tooltip-top {
  padding: $tooltip-arrow-height 0;

  .arrow {
    bottom: 0;

    &::before {
      top: 0;
      border-width: $tooltip-arrow-height math.div($tooltip-arrow-width, 2) 0;
      border-top-color: $tooltip-arrow-color;
    }
  }
}

.bs-tooltip-right {
  padding: 0 $tooltip-arrow-height;

  .arrow {
    left: 0;
    width: $tooltip-arrow-height;
    height: $tooltip-arrow-width;

    &::before {
      right: 0;
      border-width: math.div($tooltip-arrow-width, 2) $tooltip-arrow-height math.div($tooltip-arrow-width, 2) 0;
      border-right-color: $tooltip-arrow-color;
    }
  }
}

.bs-tooltip-bottom {
  padding: $tooltip-arrow-height 0;

  .arrow {
    top: 0;

    &::before {
      bottom: 0;
      border-width: 0 math.div($tooltip-arrow-width, 2) $tooltip-arrow-height;
      border-bottom-color: $tooltip-arrow-color;
    }
  }
}

.bs-tooltip-left {
  padding: 0 $tooltip-arrow-height;

  .arrow {
    right: 0;
    width: $tooltip-arrow-height;
    height: $tooltip-arrow-width;

    &::before {
      left: 0;
      border-width: math.div($tooltip-arrow-width, 2) 0 math.div($tooltip-arrow-width, 2) $tooltip-arrow-height;
      border-left-color: $tooltip-arrow-color;
    }
  }
}

.bs-tooltip-auto {
  &[x-placement^="top"] {
    @extend .bs-tooltip-top;
  }
  &[x-placement^="right"] {
    @extend .bs-tooltip-right;
  }
  &[x-placement^="bottom"] {
    @extend .bs-tooltip-bottom;
  }
  &[x-placement^="left"] {
    @extend .bs-tooltip-left;
  }
}

// Wrapper for the tooltip content
.tooltip-inner {
  max-width: $tooltip-max-width;
  padding: $tooltip-padding-y $tooltip-padding-x;
  color: $tooltip-color;
  text-align: center;
  background-color: $tooltip-bg;
  @include border-radius($tooltip-border-radius);
}

// Selectable content
//
// <h5>Selectable content</h5>
// <p>Use these when the content is a list of objects that could potentially need actioning by a user.</p>
//
// <h5>Context of use</h5>
// <p>Document lists, user lists, communications, registers</p>
//
// <h5>Where it is used</h5>
// <p>Dashboard, Project Overview, Company Overview, Register view</p>
//
// <h5>How it works</h5>
// <p>Inherits the styilng of tables</p>
// <p>Unlike tables, each row should be fully responsive</p>
// <p>The checkbox select will always be the first element of any row (left)</p>
// <p>The action icons should always be the last element of any row (right)</p>
// <p>The remaining content can be up to user's discreetion. It is reccomended to keep the title and names of the elements close to the checkbox selector so the user does not have to scan distances when making the decision to select that row. Remaining positioning should be justified across the row (justified)</p>
// <p>All items are deselected by default</p>
// <p>Once an item is selected, it becomes highlighted to indicate so and is added to the selected document pool</p>
// <p>The file widget will indicate the items has been added, and will await further action</p>
// <p>The selected checkboxes should remained checked regardless of how a user navigates around a project</p>
// <p>The scope of remembered checkboxes should be restricted to each project, to avoid cross pollination of unrelated files</p>
//
//
// Markup: ../../../html/selection-list.html
//
// Styleguide Patterns.selection

.selectable-content {
  background: $white
}

.selectable-content__wrapper {
  background: $white;
  border-radius: $border-radius;
  overflow-y: hidden;
}

.selectable-content__row,
.selectable-content__header {
  border: $border-width solid $gray-200;
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
}

.selectable-content__header {
  font-size: 0.875rem;
  font-weight: $font-weight-bold;
  background-color: $gray-100;
  color: $blue-pale;
  border-radius: $border-radius $border-radius 0 0;
}

.selectable-content__row {
  color: $blue-pale;
  font-size: 0.75rem;
  margin-top: -1px;
  &:hover {
    background: $gray-100;
  }
  .col,
  [class*="col-"] {
    padding-bottom: 0.125rem;
    padding-top: 0.125rem;
  }
  &.selectable-content__row--subrow {
    background: lighten($secondary-tint, 20%);
  }
}
.selectable-content__row--collapsed {
  display: block;
}
.selectable-content__row--collapsed,
.selectable-content__row--collapsed.collapsing {
  margin-right: -15px;
  margin-left: -15px;
  .selectable-content__row {
    background: lighten($secondary-tint, 20%);
    margin-left: 0;
    margin-right: 0;
  }
  .selectable-content__row:first-child {
    border-top: 0;
    margin-top: 0;
  }
}
.selectable-content__row--selected {
  box-shadow: inset 0 0 1px 1px $teal;
}

.selectable-content__title {
  color: $gray-800;
  font-size: 0.875rem;
}
.selectable-content__icon {
  font-size: 0.875rem;
  margin-right: 0.5rem;
  color: $link-color;
  @include hover-focus-active {
    color: $link-hover-color;
    border-bottom: 1px solid $link-hover-color;
  }
  &:last-child {
    margin-right: 0.5625rem;
  }
}

.selectable-content__actions {
  .collapsed {
    .fa-minus-square {
      // .fa-plus-square; TODO (tgraham): could this be done in code?
      content: "\f0fe";
    }
  }
}
.selectable-content__checkbox {
  position: relative;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  height: 1rem;
  width: 1rem;
  label {
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 50%;
    cursor: pointer;
    height: 1rem;
    left: 0;
    position: absolute;
    top: 3px;
    width: 1rem;
  }
  label:after {
    border-bottom: 2px solid #fff;
    border-left: 2px solid #fff;
    content: "";
    height: 0.3125rem;
    left: 0.1875rem;
    opacity: 0;
    position: absolute;
    top: 0.25rem;
    transform: rotate(-45deg);
    width: 0.5rem;
  }
  input[type="checkbox"] {
    visibility: hidden;
  }
  input[type="checkbox"]:checked + label {
    background-color: #66bb6a;
    border-color: #66bb6a;
  }
  input[type="checkbox"]:checked + label:after {
    opacity: 1;
  }
}
.selectable-content__links {
  .selectable-content__icon {
    color: $quaternary-tint;
    &--red {
      color: $danger;
      border-color: $danger;
    }
    &--green {
      color: $success;
      border-color: $success;
    }
    &--blue {
      color: $info;
      border-color: $info;
    }
  }
}

@media (min-width: map-get($grid-breakpoints, 'xs')) {
  .selectable-content__header >
  .selectable-content__cell {
    display: none;
  }
}

@media (min-width: map-get($grid-breakpoints, 'lg')) {
  .selectable-content {
    display: table !important;
  }
  .selectable-content__wrapper {
    padding-left: 0;
    padding-right: 0;
  }
  .selectable-content__row {
    .col,
    [class*="col-"] {
      padding-bottom: 0.5rem;
      padding-top: 0.5rem;
    }
  }
  .selectable-content__row {
    color: $gray-800;
    display: table-row !important;
  }
  .selectable-content__row--selected {
    box-shadow: inset 0 0 2px 2px $teal;
  }
  .selectable-content__row--collapsed {
    display: table-row-group;
  }
  .selectable-content__row,
  .selectable-content__header {
    font-size: 0.8rem;
    font-weight: 400;
    .selectable-content__title,
    .selectable-content__icon {
      font-size: 0.8rem;
    }
    .selectable-content__select {
      -ms-flex: 0 0 5%;
      flex: 0 0 5%;
      max-width: 5%;
    }
    .selectable-content__links {
      -ms-flex: 0 0 12.5%;
      flex: 0 0 12.5%;
      max-width: 12.5%;
    }
  }
  .selectable-content__header {
    display: table-header-group !important;
    font-size: 0.875rem;
    font-weight: $font-weight-bold;
    text-align: center;
    vertical-align: middle;
  }
  .selectable-content__actions {
    white-space: nowrap;
    .selectable-content__icon {
      margin-left: 0.5rem;
      margin-right: 0;
    }
  }
  .selectable-content__links {
    white-space: nowrap;
  }
  .selectable-content__cell {
    padding-top: 1em;
    padding-bottom: 1em;
  }
  .selectable-content__header >
  .selectable-content__cell {
    display: table-cell;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    vertical-align: middle;
  }
  .selectable-content__cell {
    display: table-cell;
    width: initial;
    border-bottom: $border-width solid $gray-200;
    &:first-child {
      border-left: $border-width solid $gray-200;
    }
    &:last-child {
      border-right: $border-width solid $gray-200;
    }
  }
}
// Action bar
//
// <h5>Description</h5>
// <p>The action bar is a sticky bar that is used to group actions that when interacted with, will alter information on a page.</p>
//
// <h5>Context of use</h5>
// <p>The action bar highlights key navigational components related to tables or arrangements of data. The actions are generally realted pagination, search that is confined to data on that page only or filtering.</p>
//
// <h5>Where it is used</h5>
// <p>Document and user tabs on the Project and Company overview pages.</p>
//
// <h5>How it works</h5>
// <ul>
// <li>For visual balance, the search bar should take up any remaining space in the action bar after the widths of the other elements have been taken into consideration.</li>
// <li>Once the user starts to scroll and the bar exits the view port the following becomes true: When a user starts to scroll back towards the top of the screen, the bar will slide in from the top of the page and stay fixed to the top of the screen. This means the section will be easily accessable without returning to the top of the page.</li>
// <li>The mobile design will be static and will not obey the same positioning as tablet and desktop.</li>
// </ul>
//
// Markup: ../../../html/action-bar.html
//
// Styleguide Patterns.Action-bar


.action-bar {
  font-size: $font-size-sm;
  background-color: $gray-100;
  padding: 1rem;
  justify-content: space-between;
  @include media-breakpoint-up(xl) {
    display: flex;
  }
  &__trigger {
    margin-left: 1rem;
    &.collapsed {
      i:before {
        content: '\f0fe';
      }
    }
    @include media-breakpoint-up(xl) {
      display: none;
    }
  }
  &__search {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-grow: 1;
  }
  &__select {
    width: 140px;
    display: none;
    @include media-breakpoint-up(md) {
      display: flex;
    }
  }
  &__input {
    .btn, .form-control {
      font-size: $font-size-sm;
    }
  }
  @include media-breakpoint-up(md) {
    &__input, &__select {
      & + & {
        margin-left: .5rem;
      }
    }
  }
}
.page-navigation {
  align-items: center;
  @include media-breakpoint-up(xl) {
    display: flex !important;
  }
  &__select {
    margin-top: 1rem;
    @include media-breakpoint-up(md) {
      display: none;
    }
  }
  &__pagination {
    margin-bottom: 0;
    margin-top: 1rem;
    @include media-breakpoint-up(xl) {
      margin-top: 0;
    }
  }
  &__listings {
    margin-top: 1rem;
    @include media-breakpoint-up(md) {
      margin-right: 1.5rem;
    }
    @include media-breakpoint-up(xl) {
      margin-top: 0;

    }
  }
}
.listings {
  align-items: center;
  display: flex;
  &__desc {
    margin-left: 1em;
    white-space: nowrap;
    color: $quaternary;
    font-size: $font-size-xs;
  }
  &__control {
    width: 5rem;
  }
}
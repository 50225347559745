// Alert
//
// <h5>Description</h5>
// <p>Alerts are also used to bring important notes to a user's attention. Alerts communicate conditions, indicate an event, or show responses to user actions</p>
//
// <h5>Context of use</h5>
// <p>Used for information, confirmation, warnings, and error alerts.</p>
//
// <h5>Where it is used</h5>
// <p>Across the website when ever delivering important information or warnings is required.</p>
//
// <h5>How it works</h5>
// <p>Alert types are assigned appropriate colors and icons to help indicate content and urgency. The available alert types are general, warning and success. Setting the right color and icon for an alert ensures users understand the nature of the alert at a glance, and that they take appropriate action.</p>
//
// Markup: ../../../html/alert.html
//
// Styleguide Components.alert


//
// Base styles
//

.alert {
  position: relative;
  padding: $alert-padding-y $alert-padding-x;
  margin-bottom: $alert-margin-bottom;
  border: $alert-border-width solid transparent;
  @include border-radius($alert-border-radius);
  padding-top: 1rem;
  h4 {
    font-size: 1.5rem;
    .fal {
      font-size: 1.25rem;
      margin-right: 0.25rem;
    }
  }
}

// Headings for larger alerts
.alert-heading {
  // Specified to prevent conflicts of changing $headings-color
  color: inherit;
}

// Provide class for links that match alerts
.alert-link {
  font-weight: $alert-link-font-weight;
}


// Dismissible alerts
//
// Expand the right padding and account for the close button's positioning.

.alert-dismissible {
  padding-right: ($close-font-size + $alert-padding-x * 2);

  // Adjust close link position
  .close {
    font-size: 1rem;
    line-height: 1.5rem;
    position: absolute;
    top: 0;
    right: 0;
    padding: $alert-padding-y $alert-padding-x;
    margin-top: 0.5rem;
    color: inherit;
    opacity: 1;
    text-shadow: none;

    outline: none;
    .fal {
      margin-left: 0.25rem;
      font-size: 1.5rem;
    }
    @include hover-focus-active {
      text-decoration: underline;
    }
  }
}


// Alternate styles
//
// Generate contextual modifier classes for colorizing the alert.

//@each $color, $value in $theme-colors {
//  .alert-#{$color} {
//    @include alert-variant(theme-color-level($color, -10), theme-color-level($color, -9), theme-color-level($color, 6));
//  }
//}


.alert-info {
  @include alert-variant($mischka, transparent, $gray-800);
  .close, h4 {
    color: $gray-800;
  }
}

.alert-danger {
  @include alert-variant($mischka, transparent, $red);
  .close, h4 {
    color: $red;
  }
}

.alert-success {
  @include alert-variant($jungle-mist, transparent, $green);
  .close, h4 {
    color: $green;
  }
}
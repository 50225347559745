// Dropdown
//
// <h5>Description</h5>
// <p>Dropdowns are form elements that allow users to provide input when needed. They are designed to reduce the amount of visual space required to display large sets of options.</p>
//
// <h5>Context of use</h5>
// <p>Dropdown menus are used when you have more than 2 options.</p>
//
// <h5>Where it is used</h5>
// <p>Avatars, File selection widget, Forms</p>
//
// <h5>How it works</h5>
// <ul>
// <li>Dropdown menus let users select an action or option from a set of choices.</li>
// <li>If there are less than 6 options, show all options instead of a drop down</li>
// <li>The direction of the drop down should be flexible and move towards the area with the most available space.</li>
// <li>Selected options should indicate so with a green tick.</li>
// <li>The width on mobile is set to block width to utilise the entire screen. On tablet and desktop devices, the form should grow to the legth of the content.</li>
// <li>In scenarios where items are being assosiated to a group or entity, the option to create a new group should be available. A user will enter data into the form and upon hitting the add button, the new entity will be created and the selected items added.</li>
// </ul>
//
// Markup: ../../../html/dropdown.html
//
// Styleguide Components.dropdown

// The dropdown wrapper (`<div>`)

.dropup,
.dropdown {
  position: relative;
}

.dropdown-toggle {
  // Generate the caret automatically
  @include caret;
}

// The dropdown menu
.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: $zindex-dropdown;
  display: none; // none by default, but block on "open" of the menu
  float: left;
  min-width: $dropdown-min-width;
  padding: $dropdown-padding-y 0;
  margin: $dropdown-spacer 0 0; // override default ul
  font-size: $font-size-base; // Redeclare because nesting can cause inheritance issues
  color: $body-color;
  text-align: left; // Ensures proper alignment if parent has it changed (e.g., modal footer)
  list-style: none;
  background-color: $dropdown-bg;
  background-clip: padding-box;
  border: $dropdown-border-width solid $dropdown-border-color;
  @include border-radius($dropdown-border-radius);
  box-shadow: $dropdown-box-shadow;
  &:not(&--alt) {
    .dropdown__avatar {
      width: 20px;
      height: 20px;
    }
    .avatar {
      @include square(28px);
      line-height: 26px;
      border-width: 0;
      margin-right: 0.75rem;
      .avatar__icon {
        font-size: 21px;
      }
    }
  }
  &--alt {
    background-color: $dropdown-bg-alt;
    .avatar {
      background: $white;
    }
  }
}

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    .dropdown-menu#{$infix}-left {
      right: auto;
      left: 0;
    }

    .dropdown-menu#{$infix}-right {
      right: 0;
      left: auto;
    }
  }
}

// Allow for dropdowns to go bottom up (aka, dropup-menu)
// Just add .dropup after the standard .dropdown class and you're set.
.dropup {
  .dropdown-menu {
    margin-top: 0;
    margin-bottom: $dropdown-spacer;
  }

  .dropdown-toggle {
    @include caret(up);
  }
}

.dropright {
  .dropdown-menu {
    margin-top: 0;
    margin-left: $dropdown-spacer;
  }

  .dropdown-toggle {
    @include caret(right);
    &::after {
      vertical-align: 0;
    }
  }
}

.dropleft {
  .dropdown-menu {
    margin-top: 0;
    margin-right: $dropdown-spacer;
  }

  .dropdown-toggle {
    @include caret(left);
    &::before {
      vertical-align: 0;
    }
  }
}

// Dividers (basically an `<hr>`) within the dropdown
.dropdown-divider {
  @include nav-divider($dropdown-divider-bg);
  margin: 0;
}

// Links, buttons, and more within the dropdown menu
//
// `<button>`-specific styles are denoted with `// For <button>s`
.dropdown-item {
  line-height: 1.75rem;
  display: block;
  width: 100%; // For `<button>`s
  padding: $dropdown-item-padding-y $dropdown-item-padding-x;
  clear: both;
  font-weight: $font-weight-normal;
  color: $dropdown-link-color;
  text-align: inherit; // For `<button>`s
  white-space: nowrap; // prevent links from randomly breaking onto new lines
  background-color: transparent; // For `<button>`s
  border: 0; // For `<button>`s
  @include hover-focus {
    color: $dropdown-link-hover-color;
    @include gradient-bg($dropdown-link-hover-bg);
  }

  &.active,
  &:active {
    color: $dropdown-link-active-color;
    @include gradient-bg($dropdown-link-active-bg);
  }

  &.disabled,
  &:disabled {
    color: $dropdown-link-disabled-color;
    background-color: transparent;
    // Remove CSS gradients if they're enabled
    @if $enable-gradients {
      background-image: none;
    }
  }
}

.dropdown-menu.show {
  display: block;
}

// Dropdown section headers
.dropdown-header {
  display: block;
  padding: $dropdown-padding-y $dropdown-item-padding-x;
  line-height: 2.25rem;
  margin-bottom: 0; // for use with heading elements
  color: $dropdown-header-color;
  white-space: nowrap; // as with > li > a
}

// custom

// .btn.dropdown-toggle,
// .btn.dropdown-toggle + .dropdown-menu {
//   width: 100%;
// }
// .btn.dropdown-toggle {
//   font-weight: 400;
//   text-align: left;
//   position: relative;
//   &:after {
//     position: absolute;
//     right: 0.75rem;
//     top: calc(50% - 0.1875rem);
//   }
// }


.dropdown-item {
  .option-input {
    line-height: 1rem;
    margin: 0.325rem 0;
  }
  .form-row {
    margin: 0;
    > :first-child {
      padding-left: 0;
    }
    > :last-child {
      padding-right: 0;
    }
  }
}

// @media (min-width: map-get($grid-breakpoints, 'lg')) {
//   .btn.dropdown-toggle,
//   .btn.dropdown-toggle + .dropdown-menu {
//     width: 250px;
//   }
// }
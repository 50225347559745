.register {
  &__input-cell {
    padding: 0;
    border: 0;
    width: 100%;
    display: block;
    background: transparent;
    outline: none;
    padding-left: 1rem;
    text-overflow: ellipsis;
  }
  &__inactive-text-num-span {
    color: black;
    padding: 0;
    border: 0;
    flex-grow: 1;
    width: 100%;
    display: block;
    outline: none;
    padding-left: 1rem;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &__inactive-date-span {
    color: black;
    padding: 0;
    border: 0;
    width: 100%;
    display: block;
    outline: none;
    padding-left: 1rem;
    text-overflow: ellipsis;
    word-spacing: -1.87px;
  }
  &__select-span {
    color: black;
    padding: 0px;
    border: 0px;
    flex-grow: 1;
    display: flex;
    align-items: center;
    height: 100%;
    outline: none;
    padding-left: 1rem;
    overflow: hidden;
    text-overflow: ellipsis;
    &:empty:before {
      content: "\200b";
    }
  }
  &__placeholder {
      color: $gray-500;
  }
}

.column-dropdown {
  &__content {
    display: flex;
    flex-grow: 1;
    flex-wrap: nowrap;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    align-items: center;
    &:hover .column-dropdown__column-button {
        opacity: 1;
    }
  }
  &__column-button {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid $gray-200;
    opacity: 0;
    border-radius: 2px;
    background-color: $white;
    margin-right: 10px;
    height: 15px;
    width: 20px;
    padding: 0;
    &:hover {
        border-color: $gray-400;
        background-color: $gray-200;
    }
    &--add {
      opacity: 1;
      background-color: $white;
    }
  }
  &__add-column-button {
    
    
    flex-grow: 1;
    width: 100%;
    max-width: unset;
    
    &:hover {
        background-color: $gray-200;
        border: 1px solid $gray-200;
    }
  }
  &__add-first-column-button {
    display: inline-block;
    align-items: center;
  }
}
.column-modal {
  &__colour-picker-button {
    border: 1px solid $gray-400;
    color: $gray-500;
    border-radius: 4px;
    min-width: 25%;
    min-height: 35px;
    position: relative;
    display: inline;
    &:hover {
        background-color: $gray-100;
    }
  }
  &__add-colour-button {
    background-color: inherit;
    border: none;
    color: $black;
    display: block;
    :hover {
        border-bottom: 1px solid $gray-500;
        margin-bottom: -1px;
        color: $gray-500;
    }
  }
  &__dropup-content {
    position: absolute;
    bottom: 100px;
    background-color: $white;
    min-width: 160px;
    box-shadow: 0 8px 16px 0 $black;
    z-index: 1;
  }
  &__colour-button {
    background-color: inherit;
    border: 1px solid $gray-200;
    color: $black;
    border-radius: 4px;
    padding: 15px 20px 15px 20px;
    margin: 4px;
    &:hover {
        background-color: $gray-100;
    };
    &:disabled {
        color: $gray-500;
        border-bottom: 3px solid $teal;
    }
  }
}
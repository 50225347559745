// Page header
//
// <h5>Description</h5>
// <p>The page header indicates where a user is on the site via the breadcrumbs, and what page they are currently viewing. There are variable options that can be stacked together dependant on use.</p>
//
// <h5>Context of use</h5>
// <p>The page header should exist on all pages, par the log-in page.</p>
//
// <h5>Where it is used</h5>
// <p>The page header is consistently present on the master page layout.</p>
//
// <h5>How it works</h5>
// <ul>
// <li>The mobile version is a stripped down version featuring the page title and byline only.</li>
// <li>In some cases on the mobile, the heading is replaced with a shorthand title describing the pages content, as opposed to the desktop version which should state the name of the document or communication being viewed. In which case the communication name or document name should be displayed in the byline.</li>
// <li>The widgets should not load on mobile as they feature secondary information only and the mobile experience should remain streamlined.</li>
// <li>When on desktop and scrolling: once the header leaves the viewport, a minimised version featuring the breadcrumb should become fixed to the top of the screen.</li>
// </ul>
//
// Markup: ../../../html/banner.html
//
// Styleguide Foundation.page.header


//
// Base styles
//

.banner {
  background-color: $white;
  &__inner {
    display: flex;
  }
  &__panel {
    display: flex;
    padding: 1rem;
    font-size: $font-size-xs;
    & + & {
      border-left: solid 1px $border-color;
    }
    &--lead {
      display: block;
      flex-grow: 1;
    }
    &--empty {
      background-color: $quaternary;
    }
  }
  &__panel-inner {
    display: flex;
    flex-grow: 1;
    align-self: center;
  }
  .avatar {
    margin-right: 1rem;
  }
  p {
    margin-bottom: 0;
  }
}

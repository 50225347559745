// Progress
//
// <h5>Description</h5>
// <p>A progress bar is a determinate indicator which is used to reduce users’ uncertainty around if the system is working. An additional benefit is that it indicates how much processing time is remaining</p>
//
// <h5>Context of use</h5>
// <p>Use when there is a calculable action that will take more than 10 seconds to process and can be measured from 0-100%</p>
//
// <h5>Where it is used</h5>
// <p>Document sandbox</p>
//
// <h5>How it works</h5>
// <ul>
// <li>The bar should always be animating to signal that the system is working on a users’ request</li>
// <li>Once 100% the progress animation circle should change to a check to indicate it is complete</li>
// <li>Ensure that it is clear what the progress bar is related to through labels or contextual copy</li>
// </ul>
//
// Markup: ../../../html/progress.html
//
// Styleguide Components.Progress


// Progress trackers
//
// <h5>Description</h5>
// <p>Progress trackers display progress through a sequence by breaking it up into multiple logical and numbered steps. </p>
//
// <h5>Context of use</h5>
// <p>Use when a user needs to be guided through a number of steps in order to complete a specified task.</p>
//
// <h5>Where it is used</h5>
// <p>Document sandbox</p>
//
// <h5>How it works</h5>
// <ul>
// <li>There are always three possible states for each step: Completed, Current, Remaining</li>
// <li>If possible, include very short descriptions of steps to help users when navigating through the steps</li>
// <li>Each step when clicked will take the user back to that step of the form. All data should remain saved when jumping between steps.</li>
// </ul>
//
// Markup: ../../../html/progress-track.html
//
// Styleguide Components.Progress-track

@use "sass:math";

$progress-track-size: 32px;
.progress-track {
  @include list-unstyled;
  display: flex;
  width: 100%;
  margin-bottom: 0;
  overflow: hidden;
  counter-reset: progress;
  &__icon {
    position: relative;
    z-index: 1;
    display: block;
    margin-left: auto;
    margin-right: auto;
    @include square($progress-track-size);
    flex-grow: 1;
    line-height: ($progress-track-size - 4);
    background-color: $teal;
    color: $white;
    border: solid 2px $green;
    border-radius: 50%;
    font-size: $font-size-base;
    text-decoration: none !important;
    &:after {
      content: '\2713';
    }
  }
  a {
    color: $quaternary;
  }
  &__item {
    position: relative;
    text-align: center;
    flex-grow: 1;
    font-size: $font-size-sm;
    color: $quaternary;
    counter-increment: progress;
    &:last-child:before {
      display: none;
    }
    &:before {
      position: absolute;
      height: 3px;
      width: 100%;
      top: math.div($progress-track-size, 2) - 1.5px;
      left: 50%;
      background-color: $green;
      content: '';
    }
  }
  &__item--active {
    &:before {
      background-color: $quaternary-tint;
    }
    .progress-track__icon {
      background-color: $white;
      color: $green;
      &:after {
        content: counter(progress);
      }
    }
  }
  &__item--active ~ &__item {
    &:before {
      background-color: $quaternary-tint;
    }
    .progress-track__icon {
      background-color: $white;
      color: $quaternary;
      border-color: $quaternary-tint;
      &:after {
        content: counter(progress);
      }
    }
  }
}
body.body--no-scroll {
  overflow: hidden;
}
.document-sandbox {
  background: $gray-200;
  border-radius: 0;
  margin-bottom: 0;
  padding: 1rem 1rem 0 1rem;
  position: absolute;
  z-index: $zindex-sticky;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  @include media-breakpoint-up(md) {
    &:before {
      transition: all ease-in-out .4s;
      display: block;
      content: '';
      position: fixed;
      bottom: 0;
      top: 0;
      right: 20rem;
      left: 0;
      background: $modal-backdrop-bg;
      opacity: $modal-backdrop-opacity;
      z-index: -1;
    }
    width: 20rem;
    max-height: 100%;
    left: auto;
    top: 0;
    right: 0;
    bottom: 0;
    transition: all ease-in-out .4s;
    &.full {
      width: 100%;
      &:before {
        right: 100%;
        opacity: 0;
      }
    }
  }
  h2 {
    font-size: 1.6875rem;
  }
  h4 {
    font-size: 1.375rem;
  }
  .text-muted {
    font-size: 0.75rem;
    line-height: 1.5rem;
  }
  &__filebox {
    border: 1px dashed $quaternary;
    padding: 1rem;
  }
  &__file {
    padding-bottom: 1rem;
  }
  &__progress .progress {
    width: calc(100% - 1.5rem);
  }
  &__icon {
    line-height: 1rem;
    width: 1rem;
  }
  &__buttons {
    .btn {
      flex-grow: 1;
      .btn:last-child {
        flex-grow: 2;
      }
    }
  }
  .close {
    color: $tertiary;
    font-size: 0.75rem;
    line-height: 1rem;
    margin-top: 0;
    padding-top: 1rem;
    .fal {
      font-size: 1rem;
    }
  }
}
// Sidebar navigation menu
//
// <h5>Description</h5>
// <p>The main navigational area a user will use to navigate through the project.</p>
//
// <h5>Context of use</h5>
// <p>The main navigation tree exists in this menu. All items listed here related to the project currently selected.</p>
//
// <h5>Where it is used</h5>
// <p>Across the whole website.</p>
//
// <h5>How it works</h5>
// <ul>
// <li>The sidebar should remain fixed to the top of the page. As a user scrolls through content, the bar will not move.</li>
// <li>Default bar position is collapsed. If a user chooses to expand the menu, the system should remember this preference and stay expanded until user chooses to collapse it again. There is an exception if the screen size adjusts to small in which the menu will collapse into a burger menu.</li>
// <li>A user can switch between collapsed and expanded in two ways. They can grab the right edge of the menu and extend it out with a mouse click and drag. They can also click the right chevron icon at the bottom on the menu to activate a sliding out animation. The opposite directions perform the collapsing of the menu.</li>
// <li>The ‘active’ section of the site should remain highlighted in the side menu. For example if a user is on a page that exists under the Cost Management branch, then the icon for Cost Management should be highlighted (see ‘active selection’). When menu is expanded, the active section of the site should remain expanded (see ‘Expanded - active dropdown’).</li>
// <li>A user can navigate to a companies different projects by interacting with the project avatar at the top of the menu.</li>
// <li>The ‘Recent locations’ section should load a users most recently visited URLs. Cap at five most recently visited, excluding the current URL.</li>
// </ul>
//
// Markup: ../../../html/sidebar.html
//
// Styleguide Foundation.Sidebar
@use "sass:math";

.sidebar-group {
  flex-shrink: 0;
  border-radius: 0;
  border: none;
  background-color: transparent;
  &__heading {
    margin-bottom: 0;
    white-space: nowrap;
  }
  &__trigger {
    display: flex;
    align-items: center;
    padding: .5rem 1rem;
    transition: $transition-base;
    &:hover, &:focus {
      text-decoration: none;
    }
    &:not(.collapsed) {
      background-color: $gray-200;
    }
  }
  &__collapse {
    background-color: $gray-200;
  }
  &__title {
    flex: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: .85rem;
    transition: $transition-base;
    max-width: 100%;
    padding-left: 0.5rem;
  }
  &__icon {
    display: inline-block;
    text-align: center;
    border-radius: 50%;
    background-color: transparent;
    transition: $transition-base;
    color: $primary;
    @include square(2rem);
    line-height: calc(2rem - 6px);
    margin-left: auto;
    margin-right: auto;
  }
  &__list {
    @include list-unstyled;
    margin-bottom: 0;
  }
  &__list-item {
    display: block;
    padding: .5rem 1rem;
    transition: $transition-base;
    &:hover {
      background-color: $gray-100;
      text-decoration: none;
    }
    &:focus {
      text-decoration: underline;
    }
  }
  a {
    color: $primary;

  }
}

.sidebar-footer {
  display: none;
  width: 100%;
  flex-wrap: nowrap;
  align-items: center;
  margin-top: auto;
  padding-left: 1rem;
  padding-right: 1rem;
  flex-shrink: 0;
  transition: $transition-base;
  @include media-breakpoint-up(md) {
    display: flex;
  }
  &__trigger, &__link {
    color: $primary;
    transition: $transition-base;
    &:hover, &:focus {
      color: $primary;
      text-decoration: none;
    }
  }
  &__trigger {
    flex-grow: 0;
    flex-basis: 1;
    padding-right: .5rem;
    padding-left: .5rem;
    height: $sidebar-icon-size;
    line-height: $sidebar-icon-size;
    font-size: math.div($sidebar-icon-size, 2);
    text-align: center;
  }
  &__link {
    flex-basis: 0;
    flex-grow: 2;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.sidebar {
  position: absolute;
  top: 0;
  right: 0;
  z-index: $zindex-fixed;
  display: flex;
  flex-flow: column;
  width: 250px;
  background-color: $quaternary-tint;
  color: $primary;
  font-size: .85rem;
  transition: all .35s cubic-bezier(0.5, 1.1, 0.8, 1.1);
  box-shadow: 0 0 15px 0 rgba(0,0,0,0.25);
  transform: translateX(100%) scaleX(.5);
  will-change: transform;
  @include media-breakpoint-up(md) {
    position: static;
    transform: translateX(0) scaleX(1);
  }
  &__inner {
    display: flex;
    flex-flow: column;
    flex: 1 1 auto;
    overflow-y: auto;
  }
  &__project {
    display: none;
    @include media-breakpoint-up(md) {
      display: block;
    }
    .project__trigger {
      padding-top: 1rem;
      &:after {
        position: absolute;
        right: 5px;
        color: $primary;
        transition: $transition-base;
      }
      .project__title {
        font-weight: bold;
      }
    }
  }
  &__title {
    display: inline-block;
    margin-top: 0;
    text-transform: uppercase;
    font-weight: 700;
  }
  &__link {
    display: none;
    margin-top: auto;
    overflow: hidden;
    flex-basis: 0;
    flex-shrink: 0;
    @include media-breakpoint-up(md) {
      display: block;
    }
    &:hover {
      .sidebar-group__icon {
        background-color: $gray-800;
        border-color: $quaternary;
      }
    }
    &:focus {
      .sidebar-group__icon {
        color: $tertiary;
        border-color: $tertiary;
        background-color: transparent;
      }
    }
    &:active {
      .sidebar-group__icon {
        color: $quaternary;
        border-color: $quaternary;
      }
      &:focus {
        .sidebar-group__icon {
          color: $gray-800;
          border-color: $gray-800;
        }
      }
    }
  }
  &--collapsed {
    @include media-breakpoint-up(md) {
      background-color: $gray-900;
      width: 100px;
      .sidebar-group__icon {
        color: $gray-500;
        @include square($sidebar-icon-size);
        line-height: ($sidebar-icon-size - 6);
        font-size: math.div($sidebar-icon-size, 2);
        border: solid 3px $gray-800;
      }
      .sidebar__link {
        padding: .5rem 1rem;
        flex-basis: auto;
      }
      .sidebar-group__trigger {
        &:hover {
          .sidebar-group__icon {
            background-color: $gray-800;
            border-color: $quaternary;
          }
        }
        &:focus {
          .sidebar-group__icon {
            color: $tertiary;
            border-color: $tertiary;
            background-color: transparent;
          }
        }
        &:active, &.sidebar-group__trigger--active {
          .sidebar-group__icon {
            color: $quaternary;
            border-color: $quaternary;
          }
          &:focus {
            .sidebar-group__icon {
              color: $gray-800;
              border-color: $gray-800;
            }
          }
        }
      }
      .sidebar-group__title {
        flex-grow: 0;
      }
      .sidebar-footer__trigger {
        flex-grow: 1;
        transform: rotate(-180deg);
        color: $gray-500;
      }
      .sidebar-footer__link {
        flex-grow: 0;
      }
      .project__trigger {
        .project__text {
          flex-grow: 0;
        }
        &:after {
          color: $white;
        }
      }
    }
  }
  &--open {
    transform: translateX(0) scaleX(1);
  }
}

// Avatar
//
// <h5>Description</h5>
// <p>Avatars are used to quickly identify users, projects or company.</p>
//
// <h5>Context of use</h5>
// <p>Allows logged in user to access their profile, project or company page and to easily identify their position in the navigation tree.</p>
//
// <h5>Where it is used</h5>
// <p>Profile is located in the top right on all pages when user is logged in and instances where a user needs to be identified. The project and company avatar is used in the side menu-bar.</p>
//
// <h5>How it works</h5>
// <ul>
// <li>When no image is available, the avatar defaults to the appropriate avatar type</li>
// <li>Clicking on the image will redirect the user to the parent page e.g user avatar should take the user to their profile</li>
// <li>The project avatar should display the project code by default</li>
// </ul>
//
// Markup: ../../../html/avatar.html
//
// .avatar--dragged - When a file is being dragged onto the upload area
// .avatar--sm - A small avatar
// .avatar--xs - An extra small avatar
//
// Styleguide Components.avatars


//
// Base styles
//


$avatar-size: 120px;
$avatar-size-sm: 60px;
$avatar-size-xs: 36px;
.avatar {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  @include square($avatar-size);
  line-height: ($avatar-size - 4);
  text-align: center;
  border: solid 2px $quaternary;
  border-radius: 50%;
  color: $primary;
  font-weight: bold;
  background-color: $quaternary-tint;
  transition: $transition-base;
  overflow: hidden;
  &:after {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 50%;
    background-color: $quaternary;
    content: "";
    opacity: 0;
    transform: scale(0);
    transition: $transition-base;
  }
  &__icon {
    font-size: ($avatar-size * .5);
    line-height: inherit;
    opacity: 1;
    transition: $transition-base;
  }
  &__img {
    position: absolute;
    top: 50%;
    left: 50%;
    @include square(100%);
    transform: translate(-50%, -50%);
    object-fit: cover;
    object-position: center;
  }
  &--dragged {
    border-color: $quaternary-tint;
    &:after {
      opacity: 1;
      transform: scale(1);
    }
    .avatar__icon, .avatar__img {
      opacity: 0;
    }
  }
  &--sm {
    @include square($avatar-size-sm);
    line-height: ($avatar-size-sm - 2);
    border-width: 1px;
    font-size: $font-size-sm;
    .avatar__icon {
      font-size: ($avatar-size-sm * .5);
    }
  }
  &--xs {
    @include square($avatar-size-xs);
    line-height: ($avatar-size-xs - 2);
    border-width: 1px;
    font-size: $font-size-xs;
    .avatar__icon {
      font-size: ($avatar-size-xs * .5);
    }
  }
}




.search {
  background-color: $primary;
  border-radius: $border-radius;
  & &__control { //for specificity
    background-color: inherit;
    border-color: transparent;
    border-right: 0;
    &:focus {
      border-color: $white;
      + .input-group-append .search__btn {
        border-color: $white;
      }
    }
  }
  &__btn {
    border-left: 0;
    color: $white;
    background-color: $primary;
  }
}
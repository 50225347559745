html {
  display: flex;
  flex-direction: column;
  height: 100%;
}
body {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: hidden;
  height: 100%;
}
.wrapper {
  position: relative;
  display: flex;
  flex-grow: 1;
  overflow: hidden;
  height: 100%;
  // Create new fixed context for sandbox background overlay
  // NOTE: Not sure why this is required, but it is breaking drag and drop scenarios.
  // transform: translate3d(0,0,0);
}
.content {
  position: relative;
  display: flex;
  width: 100%;
  flex-grow: 1;
  flex-direction: column;
  overflow: hidden;
  &__inner {
    position: relative;
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
}

// Permission table
//
// <h5>Description</h5>
// <p>Table containing user permission toggles.</p>
//
// Markup: ../../../html/permission-table.html
//
// Styleguide Patterns.Permission-table

.permission-table {
  thead {
    color: $blue-pale;
  }
  tbody th:first-child {
    background-color: $jungle-mist;
    padding: 0.25rem 1rem;
  }
  .permission-table__group-heading > th:first-child {
    background-color: $cyan;
    color: $white;
  }
  .permission-table__group-heading,
  tbody .permission-table__apply-all {
    background: $gray-100;
  }
}


// Tri-Toggle button
//
// <h5>Description</h5>
// <p>Switches always indicate whether a setting is on or off.</p>
//
// <h5>Context of use</h5>
// <p>Toggles should be used where there are instantaneous actions as a result of toggling between true/false options. </p>
//
// <h5>Where it is used</h5>
// <p>Permissions page, settings</p>
//
// <h5>How it works</h5>
// <ul>
// <li>In some cases, a tripple select toggle can be used instead of radios when there are three options</li>
// <li>The placement of the toggle should provide context as to what the status change is affecting. For example in a table format the table heading should be indiactvive of what the user is toggling between. When using the toggle outside of a table, ensure a label is used.</li>
// <li>Always stack toggles vertically, never horizontally</li>
// </ul>
//
// Markup: ../../../html/tri-toggle.html
//
// .tri-toggle--disabled - A toggle button which is disabled.
//
// Styleguide Components.tri-toggle

.tri-toggle {
  position: relative;
  display: flex;
  align-items: stretch;
  margin-bottom: 20px;
  font-size: $font-size-xs;
  width: $tri-toggle-width;
  z-index: 0;
  &__handle {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    pointer-events: none;
    display: block;
    &:after {
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      width: $tri-toggle-width;
      height: $toggle-height;
      vertical-align: middle;
      border-radius: $toggle-height;
      background-color: $gray-200;
      transition: .25s ease-in-out;
      content: ' ';
    }
    &:before {
      position: absolute;
      top: $toggle-spacing;
      right: auto;
      display: block;
      @include square($toggle-handle-size);
      z-index: 1;
      transition: .25s ease-in-out;
      content: '';
      border-radius: 100%;
      background: $white;
      left: (($tri-toggle-width / 2) - ($toggle-handle-size / 2));
    }
    &--no {
      &:after {
        box-shadow: inset 0 0 0 $toggle-height $danger;
      }
      &:before {
        left: $toggle-spacing;
      }
    }
    &--yes {
      &:after {
        box-shadow: inset 0 0 0 $toggle-height $teal;
      }
      &:before {
        left: ($tri-toggle-width - $toggle-handle-size - $toggle-spacing);
      }
    }
  }
  &__label {
    z-index: 2;
    flex-grow: 1;
    height: $toggle-height;
    margin-bottom: 0;
    font-family: "Font Awesome 5 Light";
    position: relative;
    cursor: pointer;
    &:before {
      pointer-events: none;
      position: absolute;
      top: ($toggle-spacing * 2);
      font-size: ($toggle-handle-size - $toggle-spacing);
      line-height: ($toggle-handle-size - ($toggle-spacing * 2));
      color: rgba($black, .25);
      opacity: 0;
    }
  }
  &__control {
    &, &:active {
      position: absolute;
      left: -5000px;
      height: 0;
      width: 0;
      opacity: 0;
      border:none;
      outline:none;
    }
    &:checked + .tri-toggle__label > .toggle__label--icon {
        opacity: 1;
    }
    &--no {
      + .tri-toggle__label > .tri-toggle__label--icon {
        left: ($tri-toggle-width - $toggle-handle-size);
      }
    }
    &--yes {
      + .tri-toggle__label > .tri-toggle__label--icon {
        left: ($toggle-spacing * 2);
      }
    }
  }
  &--disabled {
    cursor: not-allowed;
    pointer-events: none;
    .tri-toggle__handle {
      &:before {
        background-color: $gray-200;
      }
      &:after {
        background-color: $gray-500;
      }
    }
    .tri-toggle__handle--no {
      &:after {
        box-shadow: inset 0 0 0 $toggle-height $red-dark;
      }
    }
    .tri-toggle__handle--yes {
      &:after {
        box-shadow: inset 0 0 0 $toggle-height $green;
      }
    }
  }
}

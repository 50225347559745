// Radio
//
// <h5>Description</h5>
// <p>Radio buttons are used when there is a list of two or more options that are mutually exclusive and the user must select exactly one choice. </p>
//
// <h5>Context of use</h5>
// <p>As radio options are mandatory, they should be used where a prefference is required on a form. Use radio button lists when you want a user to select a single option from a list of visible options. </p>
//
// <h5>Where it is used</h5>
// <p>Forms</p>
//
// <h5>How it works</h5>
// <ul>
// <li>Radios should only be used when a single selection is needed and there are multiple options (4+) to choose from</li>
// <li>One active radio is mandatory and should always be the first radio in the list</li>
// <li>The changed settings should not take effect until the user clicks the command button</li>
// </ul>
//
// Markup: ../../../html/radio.html
//
// disabled - disabled state
//
// Styleguide Components.radio


// Checkbox
//
// <h5>Description</h5>
// <p>Checkboxes are used when there are lists of options and the user may select any number of choices, including zero, one, or several. They are often not used for mandatory questions.</p>
//
// <h5>Context of use</h5>
// <p>Used in forms, tables and cards where users are presented with the option to select one or more options. </p>
//
// <h5>Where it is used</h5>
// <p>Forms</p>
//
// <h5>How it works</h5>
// <ul>
// <li>A group of checkboxes can allow multiple choices at any given time.</li>
// <li>A stand-alone checkbox is used for a single option that the user can turn on or off.</li>
// <li>A checkbox is independent of all other checkboxes in the list, so checking one box doesn't uncheck the others</li>
// <li>Ensure there is instructional text when using a list of checkboxes to ensure the objective is clear. Use positive and active wording for checkbox labels, so that it's clear what will happen if the user turns on the checkbox.</li>
// <li>Use subheads to break up a long list of checkboxes into logical groups. This makes the choices faster to scan and easier to understand</li>
// <li>The changed settings should not take effect until the user clicks the command button</li>
// </ul>
//
// Markup: ../../../html/checkbox.html
//
// disabled - disabled state
//
// Styleguide Components.checkbox


//
// Base styles
//

@use "sass:math";

$checkbox-radio-size: 1rem;
$checkbox-dist: math.div(($checkbox-radio-size * $line-height-base) - $checkbox-radio-size, 2);
.checkbox-radio {
  display: flex;
  align-items: flex-start;
  margin: 0 0 10px;
  min-width: $checkbox-radio-size;
  min-height: $checkbox-radio-size;
  cursor: pointer;
  &__label {
    padding-left: $checkbox-radio-size * 1.5;
    line-height: $checkbox-radio-size;
  }
  &__box-wrapper {
    position: relative;
    margin-bottom: 0;
    font-weight: $font-weight-base;
    > .checkbox-radio__box, > .checkbox-radio__check {
      position: absolute;
      left: 0;
      display: block;
    }
    > .checkbox-radio__box {
      border: solid 1px rgba($black, .1);
      background-color: $white;
      transition: background-color .25s ease-in-out;
      @include square($checkbox-radio-size);
    }
    > .checkbox-radio__check {
      pointer-events: none;
      text-align: center;
      line-height: $checkbox-radio-size;
      font-size: ($checkbox-radio-size * .65);
      opacity: 0;
      transform: scale(0);
      transition: transform .25s ease-in-out, opacity .25s ease-in-out;
    }
  }
  &__control {
    position: absolute !important;
    opacity: 0.00001;
    width: 1rem;
    height: 1rem;
    z-index: 1;
    &:focus {
      + .checkbox-radio__box-wrapper > .checkbox-radio__box {
        outline: 1px solid $primary;
        outline-offset: 1px;
      }
      &:not(:focus-visible) + .checkbox-radio__box-wrapper > .checkbox-radio__box {
        outline: none;
      }
    }
    &:checked + .checkbox-radio__box-wrapper, &:indeterminate + .checkbox-radio__box-wrapper {
      > .checkbox-radio__box {
        background-color: $secondary;
      }
      > .checkbox-radio__check {
        transform: scale(1);
        opacity: 1;
      }
    }
    &:disabled + .checkbox-radio__box-wrapper, &.checkbox-radio__control--disabled + .checkbox-radio__box-wrapper {
      color: $gray-200;
      cursor: not-allowed;
      .checkbox-radio__box {
        background-color: $gray-200;
      }
      .checkbox-radio__check {
        color: $body-color;
      }
      &[type="checkbox"] .checkbox-radio__check {
        color: $secondary;
      }
      &[type="radio"] .checkbox-radio__check {
        background-color: $secondary;
      }
    }
  }
  > .checkbox-radio__control[type="checkbox"] {
    + .checkbox-radio__box-wrapper {
      > .checkbox-radio__box, > .checkbox-radio__check {
        border-radius: $border-radius;
      }
      > .checkbox-radio__check {
        @include square($checkbox-radio-size);
        color: $white;
      }

    }
    &:disabled:checked, &.checkbox-radio__control--disabled:checked {
      + .checkbox-radio__box-wrapper {
        > .checkbox-radio__box {
          background-color: $gray-600 !important;
        }
        > .checkbox-radio__check {
          color: $gray-200 !important;
        }
      }
    }
  }
  > .checkbox-radio__control[type="radio"] {
    + .checkbox-radio__box-wrapper > .checkbox-radio__box, + .checkbox-radio__box-wrapper > .checkbox-radio__check {
      border-radius: 50%;
    }
    + .checkbox-radio__box-wrapper > .checkbox-radio__check {
      background-color: $white;
      @include square(math.div($checkbox-radio-size, 2));
      top: math.div($checkbox-radio-size, 4) + $checkbox-dist;
      left: math.div($checkbox-radio-size, 4);
    }
    &:disabled:checked, &.checkbox-radio__control--disabled:checked {
      + .checkbox-radio__box-wrapper {
        > .checkbox-radio__box {
          background-color: $gray-600;
        }
        > .checkbox-radio__check {
          background-color: $gray-200;
        }
      }
    }
  }
  &--inline {
    display: inline-block;
    & + & {
      margin-left: 1rem;
    }
  }
}

@mixin square($size) {
  height: $size;
  width: $size;
};

// Animating the link underline on hover
@mixin link-animation($line-colour: $link-color, $state: 'hover') {
  position: relative;
  &:after {
    content: "";
    height: 1px;
    background-color: $line-colour;
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    transform: scaleX(0);
    transform-origin: top left;
    transition: transform 0.2s cubic-bezier(.60, 1.50, .60, 1);
  }
  &:hover {
    text-decoration: none;
  }
  &:focus {
    outline: none;
  }
  &:#{$state}:after {
    transform: scaleX(1);
  }
}
// Toolbar
//
// <h5>Tabbed navigation</h5>
// <p>Tabs are an easy way to organize content by grouping similar information on the same page. This allows content to be viewed without having to navigate away from that page and reduces the page height to eliminate scrolling.</p>
//
// <h5>Context of use</h5>
// <p>Used when information/content displayed to the user can be divided into different sections. Also to be used in combination with action buttons that affect that area of content only.  </p>
//
// <h5>Where it is used</h5>
// <p>Company and Project overview pages</p>
//
// <h5>How it works</h5>
// <p>Clicking on a tab will switch to show the content of that tab.</p>
// <p>When loading the page the default tab (first tab) content will be visible.</p>
// <p>The most frequently visited pages should be arranged left to right.</p>
// <p>Ideally these tabs would be accessed via keyboard shortcuts to optimise user workflow.</p>
// <p>One mobile screen sizes, icons take priority over text labels in order to optimise screen real estate.</p>
// <p>If possible, include the labels on icons if there is space permitting.</p>
// <p>Once the user starts to scroll and the bar exits the view port the following becomes true: When a user starts to scroll back towards the top of the screen, the bar will slide in from the top of the page and stay fixed to the top of the screen. This means the section will be easily accessable without returning to the top of the page.</p>
//
// Markup: ../../../html/toolbar.html
//
// Styleguide Patterns.toolbar

.body--scrolled .toolbar__nav {
  box-shadow: 0 0.25rem 0.25rem rgba($black, .1);
}

.toolbar__nav {
  border-bottom: $border-width $gray-200 solid;
  border-top: $border-width $gray-200 solid;
  font-size: 0.75rem;
  .toolbar__link {
    color: $blue-pale;
    display: block;
    border-bottom: 3px solid transparent;
    border-left: 2px solid $gray-200;
    padding: 0.5rem;
    flex-grow: 1;
    text-align: center;
    color: $blue-pale;
    &:first-child {
      border-left: 0;
    }
    &.active {
      color: $gray-800;
      border-bottom: 3px solid $teal;
    }
  }
  .toolbar__tool {
    color: $gray-800;
    border-left: 2px solid $gray-200;
    border-right: 0;
    font-size: 0.875rem;
    align-self: 'center';
  }
  .toolbar__tool:first-child {
    border-left: 2px solid $gray-200;
  }
  .badge {
    font-size: 0.75rem;
  }
  .toolbar__badge--default {
    background-color: $boston;
    color: #fff;
  }
  .fal {
    font-size: 1rem;
    margin-left: 0.25rem;
    margin-right: 0.25rem;
  }
}

.toolbar__dropdown {
  .toolbar__link,
  .toolbar__link:first-child {
    border-left: 2px solid $gray-200;
  }
  .dropdown-menu {
    top: calc(100% + 1px);
    background-color: #fff;
    box-shadow: 0 0.25rem 0.25rem rgba($black, .1);
    border-radius: 0;
    padding: 0;
    a {
      border-bottom: 1px solid $gray-200;
      color: $gray-800;
    }
  }
  .dropdown-menu-right {
    margin-top: 2px;
  }
}

@media (min-width: map-get($grid-breakpoints, 'sm')) {
  .toolbar__nav {
    font-size: 0.75rem;
    .toolbar__link {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .badge {
      font-size: inherit;
      font-weight: $font-weight-normal;
    }
    .fal {
      margin-left: 0;
    }
  }
}
@media (min-width: map-get($grid-breakpoints, 'md')) {
  .toolbar__nav {
    font-size: 1rem;
    padding-left: 1rem;
    padding-right: 1rem;
    .toolbar__dropdown .toolbar__link,
    .toolbar__link {
      border-left: 0;
      padding: 1rem 0;
      margin-left: 0.5rem;
      margin-right: 0.5rem;
      flex-grow: 0;
      text-align: left;
      &:first-child {
        margin-left: 0;
      }
      &:last-child {
        margin-right: 0;
      }
    }
    .toolbar__tool {
      color: $gray-800;
      border-left: 0;
    }
    .toolbar__tool:first-child {
      border-left: 0;
    }
    .fal {
      margin-right: 0.125rem;
    }
  }
}
@media (min-width: map-get($grid-breakpoints, 'lg')) {
  .toolbar__nav {
    .fal {
      margin-right: 0.25rem;
    }
  }
}
@media (min-width: map-get($grid-breakpoints, 'xl')) {
  .toolbar__nav {
    .toolbar__link {
      margin-left: 1rem;
      margin-right: 1rem;
      &:first-child {
        margin-left: 0;
      }
      &:last-child {
        margin-right: 0;
      }
    }
  }
}

.toolbar__header {
  color: $gray-900;
  font-size: 1.25em;
  padding: 0.5rem 1rem;
  .toolbar__tool {
    color: $gray-800;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    &:last-child {
      padding-right: 0;
    }
  }
}

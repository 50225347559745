// Toggle button
//
// <h5>Description</h5>
// <p>Switches always indicate whether a setting is on or off.</p>
//
// <h5>Context of use</h5>
// <p>Toggles should be used where there are instantaneous actions as a result of toggling between true/false options. </p>
//
// <h5>Where it is used</h5>
// <p>Permissions page, settings</p>
//
// <h5>How it works</h5>
// <ul>
// <li>In some cases, a tripple select toggle can be used instead of radios when there are three options</li>
// <li>The placement of the toggle should provide context as to what the status change is affecting. For example in a table format the table heading should be indiactvive of what the user is toggling between. When using the toggle outside of a table, ensure a label is used.</li>
// <li>Always stack toggles vertically, never horizontally</li>
// </ul>
//
// Markup: ../../../html/toggle.html
//
// .toggle__control--disabled - A toggle button which is disabled.
//
// Styleguide Components.toggle

.toggle {
  display: inline-block;
  margin-bottom: 20px;
  &__label {
    position: relative;
    display: block;
    padding-left: $toggle-width + 10;
    margin-bottom: 0;
    font-weight: 400;
    cursor: pointer;
    &:after {
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      width: $toggle-width;
      height: $toggle-height;
      vertical-align: middle;
      border-radius: $toggle-height;
      background-color: $danger;
      cursor: pointer;
      transition: .25s ease-in-out;
      content: ' ';
    }
    &:before {
      position: absolute;
      top: $toggle-spacing;
      left: $toggle-spacing;
      display: block;
      @include square($toggle-handle-size);
      z-index: 1;
      transition: .25s ease-in-out;
      content: '';
      border-radius: 100%;
      background: $white;
    }
  }
  &__control {
    &, &:active {
      position: absolute;
      left: -5000px;
      height:0;
      width:0;
      opacity:0;
      border:none;
      outline:none;
    }
    &:checked + .toggle__label{
      &:after {
        box-shadow: inset 0 0 0 $toggle-height $teal;
      }
      &:before {
        left: ($toggle-width - $toggle-handle-size - $toggle-spacing);
      }
    }
    &:disabled + .toggle__label, &--disabled + .toggle__label {
      cursor: not-allowed;
      &:after {
        box-shadow: inset 0 0 0 $toggle-height $red-dark;
      }
    }
    &:disabled:checked + .toggle__label, &--disabled:checked + .toggle__label {
      &:after {
        box-shadow: inset 0 0 0 $toggle-height $green;
      }
    }
  }
}
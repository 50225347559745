.no-border-bottom{
    border-bottom: 0 !important;
}
.no-border-left{
    border-left: 0 !important;
}
.no-border-right{
    border-right: 0 !important;
}
.no-border-top{
    border-top: 0 !important;
}
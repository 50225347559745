.react-select-invalid {
    & > .react-select__control {
        border-color: $danger !important;
        &--is-focused {
            box-shadow: 0 0 0 0.2rem rgba(208, 2, 27, .25) !important;
        }
    }
    & ~ .invalid-feedback {
        display: block;
    }
}

.input-group > .react-select:not(:last-child) {
    .react-select__control {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }
}

.input-group > .react-select:not(:first-child) {
    .react-select__control {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }
}

.header {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background-color: $primary-tint;
  box-shadow: 0 -1px 0 0 #E6EAEE;
  padding-left: 15px;
  padding-right: 15px;
  flex-shrink: 0;
  height: 66px;
  z-index: $zindex-fixed;
  &__notifications {
    position: relative;
    color: $white;
    margin-right: 15px;
    font-size: 1.5rem;
    padding-top: $btn-padding-y-sm;
    padding-bottom: $btn-padding-y-sm;
    &:hover{
      color: $white;
    }
    &:focus {
      color: $tertiary;
    }
  }
  &__logo {
    margin: 5px auto;
    img {
      width: 54px;
      height: 24px;
    }
    @include media-breakpoint-up(md) {
      margin-left: 0;
      img {
        width: 100px;
        height: 45px;
      }
    }
  }
  &__search {
    width: auto;
    margin-left: 15px;
    margin-right: 15px;
    input {
      color: $white;
      @include hover-focus-active {
        color: $white;
      }
    }
  }
  &__dropdown {
    display: none;
    @include media-breakpoint-up(md) {
      display: block;
    }
    .dropdown-toggle {
      display: block;
      white-space: nowrap;
      text-decoration: none;
      padding-top: 15px;
      padding-bottom: 15px;
      outline: none;
      &:after {
        border-top-color: $white;
      }
    }
    .dropdown-menu {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
  }
  &__trigger {
    position: relative;
    width: 24px;
    height: 20px;
    display: inline-block;
    @include media-breakpoint-up(md) {
      display: none;
    }
    &--active {
      .header__burger {
        transition-delay: 75ms;
        transform: translate3d(0,9px,0) rotate(135deg);
        &:before {
          transition-delay: 0s;
          opacity: 0;
        }
        &:after {
          transition-delay: 75ms;
          transform: translate3d(0,-18px,0) rotate(-270deg);
        }
      }
    }
  }
  &__burger {
    top: 2px;
    transition-timing-function: cubic-bezier(.68,-.55,.265,1.55);
    transition-duration: .275s;
    margin-top: -2px;
    outline: none;
    &, &:before, &:after {
      display: block;
      position: absolute;
      width: 24px;
      height: 2px;
      transition-property: transform;
      border-radius: 2px;
      background-color: $white;
    }
    &:before, &:after {
      content: "";
      transition-duration: .15s;
    }
    &:before {
      top: 9px;
      transition: opacity .125s ease .275s;
    }
    &:after {
      top: 18px;
      transition: transform .275s cubic-bezier(.68,-.55,.265,1.55);
    }
  }
  &__project {
    @include media-breakpoint-up(md) {
      display: none;
    }
  }
}

.data-desc {
  display: table;
  &__row {
    display: table-row;
  }
  &__title, &__info {
    display: table-cell;
  }
  &__title {
    font-weight: bold;
    padding-right: 1em;
    text-align: right;
  }
}
$rt-namespace: 'Toastify';
$rt-toast-width: 320px !default;
$rt-toast-background: #ffffff !default;
$rt-toast-min-height: 64px !default;
$rt-toast-max-height: 800px !default;

$rt-color-default: $gray-900;
$rt-color-info: $gray-900;
$rt-color-success: $success;
$rt-color-warning: $warning;
$rt-color-error: $danger;

$rt-color-progress-default: linear-gradient(to right, #4cd964, #5ac8fa, #007aff, #34aadc, #5856d6, #ff2d55) !default;

$rt-mobile: "only screen and (max-width : 480px)" !default;
$rt-font-family: $font-family-base;
$rt-z-index: 9999 !default;

@media #{$rt-mobile} {
    .#{$rt-namespace}__toast {
        max-width: 100vw;
    }
}

@import "~react-toastify/scss/main";

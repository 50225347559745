// Pills and badges
//
// <h5>Status and badges</h5>
// <p>Pills and badges are used to indicate status or number counts. The colour coding aids in a user’s prioritisation of documents.</p>
//
// <h5>Context of use</h5>
// <p>To indicate what section of the document flow a file is currently at, the pill will appear in close proximity to the file title. To indicate a number of new notifications or pending actions required by a user.</p>
//
// <h5>Where it is used</h5>
// <p>Navigation bar to signal new communications, any where a file is displayed</p>
//
// <h5>How it works</h5>
// <ul>
// <li>Each step in the workflow is colour coded to signify a step in the journey.</li>
// <li>Colour theory has been used to assosiate meaning e.g Warm colours from a similar palette indicate outstanding actions. Green for go, red for stop, orange for actions that are transitioning or need attention.</li>
// <li>On areas where space is limited, use the small circle version of the pill.</li>
// <li>When space is available, or the status is the key focus of the user, use the regular pill.</li>
// <li>A pill’s width should be dynamic to the width of the content within.</li>
// <li>When used for numerical data, the pills should be right aligned with text right aligned.</li>
// <li>When used for text, the pills should be left aligned with centred text.</li>
// </ul>
//
// Markup: ../../../html/pills.html
//
// .badge--pending-review - Primary
// .badge--approval-required - Approval required
// .badge--pending-approval - Pending approval
// .badge--approved - Approved
// .badge--revision-required - Revision required
// .badge--rejected - Rejected
//
// Styleguide Components.pills

// Badges
//
// Dismissable badges
//
// Markup: ../../../html/badges.html
//
// Styleguide Components.pills.badges

// Button badges
//
// Buttons can contain pills
//
// Markup: ../../../html/button-pills.html
//
// Styleguide Components.pills.buttons

// Status markers
//
// Truncated status markers
//
// Markup: ../../../html/status-badges.html
//
// Styleguide Components.status-markers

// Base class
//
// Requires one of the contextual, color modifier classes for `color` and
// `background-color`.

.badge {
  display: inline-block;
  padding: $badge-padding-y $badge-padding-x;
  font-size: 1em;
  font-weight: $badge-font-weight;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  @include border-radius($badge-border-radius);

}

// Quick fix for badges in buttons
.btn .badge {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 50%;
}

// Pill badges
//
// Make them extra rounded with a modifier to replace v3's badges.

.badge-pill {
  padding-right: $badge-pill-padding-x;
  padding-left: $badge-pill-padding-x;
  @include border-radius($badge-pill-border-radius);
}

// Colors
//
// Contextual variations (linked badges get darker on :hover).

@each $color, $value in $theme-colors {
  .badge-#{$color} {
    @include badge-variant($value);
  }
}

// Custom

[class*="badge--"] {
  color: $white;
}

.badge {
  &--default {
    background: $blue-pale;
  }
  &--pending-review {
    background: $yellow;
  }
  &--approval-required {
    background: $cornflower;
  }
  &--pending-approval {
    background: $orange;
  }
  &--approved {
    background: $teal;
  }
  &--revision-required{
    background: $cyan;
  }
  &--rejected {
    background: $red;
  }
  &--close {
    a {
      color: $white;
    }
    .fal {
      margin-left: 0.25rem;
    }
  }
}

.btn--badged {
  position: relative;
  .badge {
    font-size: 0.6875rem;
    max-width: calc(100% - 2px);
    overflow: hidden;
    top: 1px;
    right: 1px;
    @extend .text-truncate;
  }
}

.badge-pill[class*="badge--"] {
  font-size: 0.75rem;
}

@media (min-width: map-get($grid-breakpoints, 'lg')) {
  .badge {
    &--close {
      .fal {
        margin-left: 0.5rem;
      }
    }
  }
  .badge-pill[class*="badge--"] {
    font-size: 0.8125rem;
    padding: 0.375rem 0.5rem;
  }
}

.status-badge {
  &:before {
    display: block;
    float: left;
    content: '•';
    font-size: 2.5rem;
    line-height: 1rem;
    margin-right: 0.25rem;
  }
  &--default:before {
    color: $blue-pale;
  }
  &--pre-warning:before {
    color: $yellow;
  }
  &--warning:before {
    color: $orange;
  }
  &--pre-success:before {
    color: $cornflower;
  }
  &--success:before {
    color: $teal;
  }
  &--post-success:before {
    color: $cyan;
  }
  &--error:before {
    color: $red;
  }
}
.react-datepicker-popper {
    z-index: $zindex-popover;
}

.react-datepicker {
    font-family: unset;
    border: unset;
    box-shadow: 0 0 0 1px hsla(0,0%,0%,0.1), 0 4px 11px hsla(0,0%,0%,0.1);
}

.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range {
    background-color: $primary
}

.react-datepicker__day.react-datepicker__day--outside-month {
    color: $gray-500
}

.react-datepicker__header {
    background-color: $white
}

.react-datepicker__today-button {
    background-color: $white;
}

.react-datepicker-wrapper {
    width: 100%;
}
.react-datepicker__input-container {
    width: 100%;
}

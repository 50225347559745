.spreadsheet {
 &__table {
   table-layout: fixed;
   overflow-x: auto;
   overflow-y: hidden;
   border: 0;
   white-space: nowrap;
   background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 1 1' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='1' height='1' fill='transparent' /%3E%3C/svg%3E"), url("data:image/svg+xml,%3Csvg viewBox='0 0 1 1' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='1' height='1' fill='hsla(210%2C18%25%2C87%25%2C1)' /%3E%3C/svg%3E");
   flex: 1 1 auto;
   background-size: 1px 40px;
   background-position-y: 13px;
   background-repeat: repeat-x, repeat;
   background-color: $gray-100;
 }
 &__header-row {
   position: sticky;
   top: 0;
   background-color: $white;
   white-space: nowrap;
   z-index: 1;
   display: flex;
   font-weight: bold;
   width: 100%;
   justify-content: left;
   font-size: 0.875rem;
   height: 34px;
   margin-bottom: 1px;
 }
 &__header-cell {
   display: flex;
   background-color: $white;
   border-bottom: 2px solid $gray-300; 
   min-width: 150px;
   max-width: 150px;
   width: 150px;
   text-align: left;
   box-shadow: inset 0 15px 3px -12px $gray-100;
   border-right: 1px solid $gray-300;
   &:hover {
       background-color: $gray-100;
   }
   &:last-of-type {
    flex-grow: 1;
    max-width: unset;
  }
 }
 &__column-resizer {
   margin-right: -0.5rem;
   width: 1rem;
   align-self: flex-end;
   right: 0%;
   height: 100%;
   cursor: col-resize;
   z-index: 2;
 } 
 &__top-left-cell {
   background: $white;
   border-bottom: 2px solid $gray-300;
   display: inline-block;
   max-width: 150px;
   padding-bottom: 4px;
   padding-left: 15px;
   text-align: left;
   min-width: 50px;
   width: 50px;
   border-right: 0;
   margin-left: 1px;
   padding-top: 8px;
   &:hover {
       background-color: $gray-100;
   }
 }
 &__body {
   position: sticky;
   max-width: 100%;
   font-size: 0.900rem;
 }
 &__index-cell {
   height: 40px;
   max-width: 150px;
   background-color: $white;
   border-bottom: 1px solid $gray-300;
   border-right: 1px solid $gray-300;
   min-width: 50px;
   width: 50px;
   display: flex;
   align-items: center;
   box-shadow: 15px 0 20px -5px $gray-300;
   position: sticky;
   left: 0;
   margin-right: 1px;
   &:hover {
       background-color: $gray-100;
   }
 }
 &__row {
   color: $gray-600;
   z-index: 3;
   background-color: $white;
   width: 100%;
   display: flex;
   max-height: 100px;
 }
 &__row-cell {
   display: flex;
   align-items: center;
   height: 40px;
   padding: 0 2px 0 2px;
   max-width: 150px;
   min-width: 150px;
   background-color: $white;
   border-bottom: 1px solid $gray-300;
   border-right: 1px solid $gray-300;
   &:hover {
       background-color: $gray-100;
   }
   &:last-of-type {
     flex-grow: 1;
     max-width: unset;
   }
 }
 &__footer {
   position: sticky;
   font-size: 0.9rem;
   bottom: 0;
   color: $gray-600;
   z-index: 1;
   background-color: $white;
   display: flex;
   max-height: 100px;
   height: 40px;
   width: 100%;
   white-space: nowrap;
 }
 &__footer-cell {
   display: flex;
   width: 150px;
   height: 40px;
   max-width: 150px;
   min-width: 150px;
   background-color: $white;
   border-bottom: 1px solid $gray-300;
   border-right: 1px solid $gray-300;
   box-shadow: inset 0 15px 3px -11px $gray-100;
   border-top: 1px solid $gray-300;
   &:hover {
       background-color: $gray-100;
   }
   &:last-of-type {
    flex-grow: 1;
    max-width: unset;
  }
 }
 &__add-row-cell {
   height: 40px;
   background-color: $white;
   border-bottom: 1px solid $gray-300;
   border-right: 1px solid $gray-300;
   width: 50px;
   min-width: 50px;
   max-width: 50px;
   display: flex;
   align-items: center;
   border-top: 1px solid $gray-300;
   box-shadow: 15px 0 20px -5px $gray-300;
   position: sticky;
   left: 0;
   &:hover {
       background-color: $gray-100;
   }
 }
 &__button {
   padding: 0;
   background-color: $white;
   border: 0;
   width: 20px;
   outline: none;
   color: $gray-600;
   &:hover {
       background-color: $gray-100;
   }
 }
}
@use "sass:math";

.project {
  &__icon {
    @include square(2rem);
    line-height: 2rem;
    text-align: center;
    background-color: $white;
    border-radius: 50%;
    margin-left: auto;
    margin-right: auto;
  }
  &__trigger {
    display: flex;
    align-items: center;
    transition: $transition-base;
    color: $primary;
    padding-top: .5rem;
    padding-bottom: .5rem;
    &:after {
      color: $white;
    }
    &:hover, &:focus {
      color: $primary;
      text-decoration: none;
    }
    @include media-breakpoint-up(md) {
      padding-right: 1rem;
      padding-left: 1rem;
      .project__icon {
        @include square($sidebar-icon-size);
        line-height: $sidebar-icon-size;
        font-size: math.div($sidebar-icon-size, 2);
      }
    }
  }
  &__text {
    flex-basis: 0;
    flex-grow: 2;
    overflow: hidden;
  }
  &__title, &__desc {
    display: block;
    padding-left: 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &__title {
    font-size: $h5-font-size;
  }
  &__menu {
    border-radius: 0 $dropdown-border-radius $dropdown-border-radius 0;
    max-width: 15rem;
    overflow-y: auto;
    max-height: 100%;
  }
  &__menu-item {
    display: flex;
    line-height: 1rem;
    padding: 0.75rem;
    &:first-child {
      border-top-left-radius: 0;
    }
    &--active {
      .project__title {
        font-weight: $font-weight-bold;
      }
    }
    .project__title {
      font-size: $small-font-size;
    }
    .project__desc {
      text-transform: uppercase;
      color: $text-muted;
      font-size: $font-size-xs;
    }
  }
}

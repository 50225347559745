// Buttons
//
// <h5>Description</h5>
// <p>Buttons are used to signal actions.</p>
//
// <h5>Context of use</h5>
// <p>Use buttons for the most important actions you want users to take.</p>
//
// <h5>Where it is used</h5>
// <p>All across the platform.</p>
// <ul>
// <li>Main .btn-default: This button is to be reserved for main actions such as uploading documents.</li>
// <li>Primary .btn-tertiary: To be used as the main action to take and when one button must stand out amongst others.</li>
// <li>Secondary .btn-primary: This is the default button style that should be used the majority of the time.</li>
// <li>Link .btn-link: These are the lowest buttons in terms of importance. These are used for actions that are not interacted with often.</li>
// <li>Outlined .btn-outline-light: These button styles are used on solid coloured backgrounds to avoid having colours clash. You can find it in the selection widget.</li>
// </ul>
//
// <h5>How it works</h5>
// <p>Ensure CTAs are descriptive and indicate what will happen when clicked.</p>
// <p>There are different colours for primary, secondary and tertiary buttons. Some buttons will have an icon in the left of the container.</p>
//
// Markup: ../../../html/button.html
//
// .btn-sm - A small button.
// .btn-lg - A large button.
// .btn-default - A default coloured button.
// .btn-primary - A primary coloured button.
// .btn-secondary - A secondary coloured button.
// .btn-tertiary - A tertiary coloured button.
// .btn-link - A button styled as a link.
// .btn-block - A full-width button.
// .disabled - A disabled button.
//
// Styleguide Components.button

// stylelint-disable selector-no-qualifying-type

//
// Base styles
//

// stylelint-disable selector-no-qualifying-type

//
// Base styles
//

.btn {
  display: inline-block;
  font-weight: $btn-font-weight;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  border: $btn-border-width solid transparent;
  @include button-size($btn-padding-y, $btn-padding-x, $font-size-base, $btn-line-height, $btn-border-radius);
  @include transition($btn-transition);
  @include box-shadow(none);
  // Share hover and focus styles

  &:focus,
  &.focus {
    outline: 0;
    text-decoration: underline;
    @include box-shadow($btn-focus-box-shadow);
  }

  // Disabled comes first so active can properly restyle
  &.disabled,
  &:disabled {
    background-color: $input-disabled-bg;
    color: $gray-500;
    border-color: $gray-500;
  }

  // Opinionated: add "hand" cursor to non-disabled .btn elements
  &:not(:disabled):not(.disabled) {
    cursor: pointer;
  }

  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active {
    background-image: none;

    &:focus {
    }
  }
}

// Future-proof disabling of clicks on `<a>` elements
a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none;
}


//
// Alternate buttons
//

@each $color, $value in $theme-colors {
  .btn-#{$color} {
    @include button-variant($value, $value);
  }
}

@each $color, $value in $theme-colors {
  .btn-outline-#{$color} {
    @include button-outline-variant($value);
  }
}


//
// Link buttons
//

// Make a button look and behave like a link
.btn-link {
  font-weight: $font-weight-normal;
  color: $link-color;
  background-color: transparent;

  @include hover {
    color: $link-hover-color;
    text-decoration: $link-hover-decoration;
    background-color: transparent;
    border-color: transparent;
  }

  &:focus,
  &.focus {
    text-decoration: $link-hover-decoration;
    border-color: transparent;
    box-shadow: none;
  }

  &:disabled,
  &.disabled {
    color: $btn-link-disabled-color;
  }

  // No need for an active state here
}


//
// Button Sizes
//

.btn-lg {
  @include button-size($btn-padding-y-lg, $btn-padding-x-lg, $font-size-lg, $btn-line-height-lg, $btn-border-radius-lg);
}

.btn-sm {
  @include button-size($btn-padding-y-sm, $btn-padding-x-sm, $font-size-sm, $btn-line-height-sm, $btn-border-radius-sm);
}


//
// Block button
//

.btn-block {
  display: block;
  width: 100%;

  // Vertically space out multiple block buttons
  + .btn-block {
    margin-top: $btn-block-spacing-y;
  }
}

// Specificity overrides
input[type="submit"],
input[type="reset"],
input[type="button"] {
  &.btn-block {
    width: 100%;
  }
}

.pointer {
    cursor: pointer;
}

.text-wrap {
    word-wrap: break-word;
}

.text-sm {
    font-size: 0.875rem;
}

.no-outline {
    outline: none !important;
}

.user-text {
    white-space: pre-line;
}

.fr-forceselectable .fr-element.fr-disabled {
    user-select: text;
}

.min-h-0 {
    min-height: 0 !important;
}

.min-h-100 {
    min-height: 100% !important;
}

.min-w-0 {
    min-width: 0;
}

.opacity-0 {
    opacity: 0 !important;
}

.opacity-1 {
    opacity: 1 !important;
}

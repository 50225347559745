// Selection Widget
//
// <h5>Description</h5>
// <p>A prominent bar to highlight key actions that can be applied to particular datasets. </p>
//
// <h5>Context of use</h5>
// <p>The widget is used in certain pages where a user can select items out of a list. These can vary, and the action buttons inside will reflect the type of data being selected. The actions will relate soley to the selected files. For example a user will select a group of items to be added to a document group or communication. They could also select all to remove files etc.</p>
//
// <h5>Where it is used</h5>
// <p>Document tabs on the Project Overview page, User tabs on Project and Company Overview pages</p>
//
// <h5>How it works</h5>
// <ul>
// <li>All action buttons should be centred. If they flow over the width of the container, then the user may scroll/swipe to access the additional buttons.</li>
// <li>As items are selected on screen, they will added to the ‘items selected’ list. When clicked, the items will appear in a dropdown where the selections can be removed quickly.</li>
// <li>Each widget should be unique between projects.</li>
// <li>The widget will remember what files are selected between pages. If an item is selected in one window, it should appear in the second window of that project as well.</li>
// <li>The buttons should show as disabled until the first item is selected, to educate users on their options</li>
// <li>This bar is sticky and remains on the bottom of any pages where a user can select items to add to the list. </li>
// </ul>
//
// Markup: ../../../html/file-select.html
//
// Styleguide Patterns.File-Select

.file-select {
  justify-content: space-between;
  align-items: center;
  margin-top: auto;
  background-color: $secondary;
  box-shadow: 0 2px 4px 0 #8E8E8E;
  color: $white;
  padding: 0.4375rem 1rem;
  line-height: 1.5rem;
  font-size: $font-size-xs;
  min-width: 50px;
  @include media-breakpoint-up(md) {
    font-size: $font-size-base;
  }
  &__scroll {
    flex-grow: 2;
    overflow-x: auto;
  }
  &__docs {
    display: flex;
    white-space: nowrap;
  }
  &__drop {
    & + & {
      margin-left: .5rem;
    }
  }
  .badge,
  .fal {
    margin-right: 0.25rem;
  }
  &__doc {
    display: inline-block;
    padding: $btn-padding-y-sm $btn-padding-x-sm;
    color: $white;
    border: solid 1px;
    border-radius: $border-radius;
    transition: $transition-base;
    &:hover, &:focus {
      color: $white;
    }
    &:after {
      display: none !important;
    }
  }
  &__instructions {
    font-size: 0.875rem;
    margin-left: 1rem;
  }
  .dropdown-menu {
    margin-bottom: 1.75rem;
  }
  &__dropdown {
    a {
      border-color: transparent;
    }
    .dropdown-menu {
      margin-bottom: 0rem;
    }
  }
  .dropdown-menu {
    border-radius: $border-radius $border-radius 0 0;
  }
  .badge {
    margin-bottom: 0;
  }
  .file-select__docs,
  .file-select__instructions {
    opacity: .66;
  }
  &__doc--selected {
    .file-select__docs,
    .file-select__instructions {
      opacity: 1;
    }
  }
}

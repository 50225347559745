.typeahead {
    display: inline-block;

    &-hint {
        background-color: transparent !important;
        border-color: transparent;
        box-shadow: none;
        color: rgba(0,0,0,0.35);
        left: 0;
        pointer-events: none;
        position: absolute;
        top: 0;
        // z-index: -1;
    }
}

.input-group > .typeahead {
    -webkit-box-flex: 1;
	-moz-box-flex: 1;
	-webkit-flex: 1;
	-ms-flex: 1;
	flex: 1;

    &:not(:first-child) .form-control {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
    
    &:not(:last-child) .form-control {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
}
